<template>
  <div>
    <del v-if="isRetired" :title="computedTitle">
      {{ formattedData }}
    </del>
    <p v-else :title="computedTitle">
      {{ formattedData }}
    </p>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import { formatos } from '@/mixins/formatos'

export default {
  components: {
    BBadge,
  },
  mixins: [formatos],
  props: {
    data: {
      type: String,
      required: false,
      default: '',
    },
    rut: {
      type: Number,
      required: false,
      default: null,
    },
    mayus: {
      type: Boolean,
      required: true,
    },
    fecha_retiro: {
      type: String,
      required: false,
      default: null,
    },
    fecha_ingreso: {
      type: String,
      required: false,
      default: null,
    },
    motivo_retiro: {
      type: String,
      required: false,
      default: null,
    },
    genero: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    isRetired() {
      return this.fecha_retiro !== null;
    },
    formattedData() {
      return this.mayus ? this.data.toUpperCase() : this.data;
    },
    computedTitle() {
      const estado = this.getEstado();
      const fechaIngreso = this.fecha_ingreso
        ? `Fecha Matriculado: ${this.formatoDiaMesYear(this.fecha_ingreso)}`
        : 'Fecha Matriculado: Sin registro';
      const fechaRetiro = this.fecha_retiro
        ? `Fecha retirado: ${this.formatoDiaMesYear(this.fecha_retiro)}`
        : 'Fecha retirado: Sin registro';
      const motivoRetiro = this.motivo_retiro
        ? `Motivo retiro: ${this.motivo_retiro}`
        : 'Motivo retiro: Sin registro';

      return this.isRetired
        ? `${estado}\n${fechaIngreso}\n${fechaRetiro}\n${motivoRetiro}`
        : `${estado}\n${fechaIngreso}`;
    },
  },
  methods: {
    getEstado() {
      if (this.genero === 1) {
        return this.isRetired ? 'Alumno Retirado' : 'Alumno Matriculado';
      } else if (this.genero === 2) {
        return this.isRetired ? 'Alumna Retirada' : 'Alumna Matriculada';
      } else {
        return this.isRetired ? 'Estudiante Retirado' : 'Estudiante Matriculado';
      }
    }
  },
}
</script>