<template>
  <BButtonGroup>
    <!-- v-if="$can('update', modulo)" -->
    <BButton
      variant="secondary"
      class="btn-sm mt-25 btn-icon rounded mr-50"
      title="Editar"
      @click="$emit('processGoToUpdate', data.item)"
    >
      <feather-icon
        icon="Edit3Icon"
      />
    </BButton>

    <BButton
      v-if="$can('retirar', modulo) && data.fecha_retiro === null"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-BModal="nombreModalRetirar"
      variant="danger"
      class="btn-sm mt-25 btn-icon rounded mr-50"
      title="Retirar alumno/a"
    >
      <!-- <feather-icon
        icon="Trash2Icon"
      /> -->
      Retirar
    </BButton>
  </BButtonGroup>
</template>

<script>
import {
  BButton, BButtonGroup, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BButtonGroup,
  },
  directives: {
    'BModal': VBModal,
    Ripple,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: false,
    },
    nombreModalRetirar: {
      type: String,
      default: 'false',
    },
  },
}
</script>
